import React,{ComponentType } from "react";
import {
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from "react-hook-form";
import Select from "react-select";

export type MultiSelectProps = {
  dataArray: any;
  errors?: FieldError | string;
  stylesType: "modal" | "regular";
  controlWidth?: string;
  multiValueMinWidth?: string;
  type?: "valueNeeded" | "checkBoxOption";
  isMulti: boolean;
  isSearchable: boolean;
  onSelectOption: any;
  selectedValue?: any;
  placeholder: any;
  maxHeight?: string;
  flexWrap?: "wrap" | "nowrap";
  flexDirection?: string;
  overflowY?: string;
  controlField?: ControllerRenderProps<FieldValues, any>;
  components?: { Option?: ComponentType<any> };
};
const Multiselect: React.FC<MultiSelectProps> = ({
  dataArray,
  type,
  errors,
  isMulti,
  isSearchable,
  onSelectOption,
  selectedValue,
  placeholder,
  stylesType,
  controlWidth,
  multiValueMinWidth,
  maxHeight,
  flexWrap,
  flexDirection,
  overflowY,
  controlField,
  components
}): JSX.Element => {

  const stylesDataModal = {
    control: (base) => ({
      ...base,
      width: controlWidth ? controlWidth : "",
      maxHeight: isMulti ? "none" : "32px",
      minHeight: isMulti ? "32px" : "32px", // isMulti adds a bit more padding so decreasing height.
      marginInlineStart: "auto",
      borderColor: !errors ? "#73809D" : "#DC1C1C",
      boxShadow: "none",
      borderRadius: "4px",
      fontFamily: "Lato, Roboto, serif",
      fontSize: "12px",
      "&:hover": {
        borderColor: !errors ? "#73809D" : "#DC1C1C",
        boxShadow: "none",
      },
      "&:focus": {
        borderColor: !errors ? "#121212" : "#DC1C1C",
        boxShadow: !errors ? "0 0 0 1px #121212" : "0 0 0 1px #DC1C1C",
      },
    }),
    placeholder: (base) => ({
      ...base,
      maxHeight: maxHeight || "32px",
      color: !errors ? "#73809D" : "#DC1C1C",
      whiteSpace: "nowrap",
      marginTop: "0px",
    }),
    input: (base) => ({
      ...base,
      margin: "0px",
      color: "#73809D",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#73809D",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingBlock: "0",
      color: !errors ? "#73809D" : "#DC1C1CBB",
      fill: !errors ? "#73809D" : "#DC1C1CBB",
      "&:hover": {
        color: "#DC1C1C",
        fill: "#DC1C1C",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingBlock: "0",
      color: !errors ? "#73809D" : "#DC1C1CBB",
      fill: !errors ? "#73809D" : "#DC1C1CBB",
      "&:hover": {
        color: errors ? "#DC1C1C" : "#121212",
        fill: errors ? "#DC1C1C" : "#121212",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      display: "flex",
      minHeight: isMulti ? "30px" : "32px", // isMulti adds a bit more padding so decreasing height.,
      maxHeight: isMulti ? "none" : "32px",
      alignSelf: "center",
      overflowX: "auto",
      flexDirection: flexDirection || "row",
      flexWrap: flexWrap ? flexWrap : "wrap",
      paddingBlock: "0",
    }),
    multiValue: (base) => ({
      ...base,
      maxHeight: "23px",
      minWidth: multiValueMinWidth ? multiValueMinWidth : "fit-content",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      maxHeight: "none",
      alignSelf: "center",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    menuList: (base) => ({
      ...base,
      border: !errors ? "1px solid #73809D" : "1px solid #DC1C1C",
      borderRadius: "4px",
      fontFamily: "Lato, Roboto, serif",
      fontSize: "12px",
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "transparent",
      color: !errors ? "black" : "#DC1C1C",
      "&:hover": {
        backgroundColor: "#F7F9FB",
      },
    }),
  };

  const stylesDataRegular = {
    control: (base) => ({
      ...base,
      width: controlWidth ? controlWidth : "",
      maxHeight: isMulti ? "none" : maxHeight || "30px",
      minHeight: isMulti ? "30px" : "30px", // isMulti adds a bit more padding so decreasing height.,
      marginInlineStart: "auto",
      borderColor: !errors ? "#73809D" : "#DC1C1C",
      boxShadow: "none",
      borderRadius: "4px",
      fontFamily: "Lato, Roboto, serif",
      fontSize: "12px",
      "&:hover": {
        borderColor: !errors ? "#73809D" : "#DC1C1C",
        boxShadow: "none",
      },
      "&:focus": {
        borderColor: !errors ? "#121212" : "#DC1C1C",
        boxShadow: !errors ? "0 0 0 1px #121212" : "0 0 0 1px #DC1C1C",
      },
    }),
    placeholder: (base) => ({
      ...base,
      maxHeight: maxHeight || "30px",
      color: !errors ? "#73809D" : "#DC1C1C",
      whiteSpace: "nowrap",
      marginTop: "0px",
    }),
    input: (base) => ({
      ...base,
      margin: "0px",
      color: "#73809D",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#73809D",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingBlock: "0",
      color: !errors ? "#73809D" : "#DC1C1CBB",
      fill: !errors ? "#73809D" : "#DC1C1CBB",
      "&:hover": {
        color: "#DC1C1C",
        fill: "#DC1C1C",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingBlock: "0",
      color: !errors ? "#73809D" : "#DC1C1CBB",
      fill: !errors ? "#73809D" : "#DC1C1CBB",
      "&:hover": {
        color: errors ? "#DC1C1C" : "#121212",
        fill: errors ? "#DC1C1C" : "#121212",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: isMulti ? "none" : maxHeight || "30px",
      minHeight: isMulti ? "28px" : "30px", // isMulti adds a bit more padding so decreasing height.,,
      alignSelf: "center",
      overflowX: "auto",
      overflowY: overflowY || "",
      flexDirection: flexDirection || "row",
      flexWrap: flexWrap ? flexWrap : "wrap",
      paddingBlock: "0",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      maxHeight: maxHeight || "30px",
      alignSelf: "center",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    multiValue: (base) => ({
      ...base,
      maxHeight: "23px",
      minWidth: multiValueMinWidth ? multiValueMinWidth : "fit-content",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    menuList: (base) => ({
      ...base,
      border: !errors ? "1px solid #73809D" : "1px solid #DC1C1C",
      borderRadius: "4px",
      fontFamily: "Lato, Roboto, serif",
      fontSize: "12px",
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "transparent",
      color: !errors ? "black" : "#DC1C1C",
      "&:hover": {
        backgroundColor: "#F7F9FB",
      },
    }),
  };

  return (
    <>
      {controlField ? (
        <Select
          className="is-invalid"
          isMulti={isMulti}
          isSearchable={isSearchable}
          placeholder={placeholder}
          options={dataArray}
          styles={stylesType === "modal" ? stylesDataModal : stylesDataRegular}
          {...{ ...controlField, onChange: onSelectOption }}
        />
      ) : type === "valueNeeded" ? (
        <Select
          className="is-invalid"
          defaultValue={selectedValue}
          isMulti={isMulti}
          value={selectedValue}
          isSearchable={isSearchable}
          placeholder={placeholder}
          onChange={onSelectOption}
          options={dataArray}
          styles={stylesType === "modal" ? stylesDataModal : stylesDataRegular}
        />
      ) : type === "checkBoxOption" ? (
          <Select
          className="is-invalid"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={components}
          defaultValue={selectedValue}
          isMulti={isMulti}
          value={selectedValue}
          isSearchable={isSearchable}
          placeholder={placeholder}
          onChange={onSelectOption}
          options={dataArray}
          styles={stylesType === "modal" ? stylesDataModal : stylesDataRegular}
        />
      ) : (
        <Select
          className="is-invalid"
          defaultValue={selectedValue}
          isMulti={isMulti}
          isSearchable={isSearchable}
          placeholder={placeholder}
          onChange={onSelectOption}
          options={dataArray}
          styles={stylesType === "modal" ? stylesDataModal : stylesDataRegular}
        />
      )}
    </>
  );
};

export default Multiselect;
