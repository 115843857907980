import React from "react";
import { getAcademicClasses, getAcademicSections } from "services/academics";
import { getBranchAcademicBatches, getBranchesByOrgId, getCombinedClassAndSections } from "services/organization";
import useApi, { UseApiReturn } from "./useApi";

export type returnType = {
    branchApi: UseApiReturn | null;
    branchAcademicBatchApi: UseApiReturn | null;
    academicClassesApi: UseApiReturn | null;
    academicSectionsApi: UseApiReturn | null;
    classSectionsApi: UseApiReturn | null;
};

type useBranchlevelProps = {
    branchAcademicBatches ?: boolean, 
    classes ?: boolean, 
    sections ?: boolean, 
    combinedClassSections ?: boolean
} | null

function useBranchLevel({branchAcademicBatches, classes, sections, combinedClassSections} : useBranchlevelProps) {
    const returnObj : returnType = {
        branchApi : null,
        branchAcademicBatchApi : null,
        academicClassesApi : null,
        academicSectionsApi : null,
        classSectionsApi : null,
    }
    returnObj.branchApi = useApi(getBranchesByOrgId);
    if(branchAcademicBatches){
        returnObj.branchAcademicBatchApi = useApi(getBranchAcademicBatches);
    }
    if(classes){
        returnObj.academicClassesApi = useApi(getAcademicClasses);
    }
    if(sections){
        returnObj.academicSectionsApi = useApi(getAcademicSections);
    }

    if(combinedClassSections){
        returnObj.classSectionsApi = useApi(getCombinedClassAndSections)
    }

    return returnObj;
}

export default useBranchLevel;