import React, { SelectHTMLAttributes } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { Options } from "utils/helper/types";

export type TSelectInput<T = string> = Omit<
  Omit<FormControlProps, "onChange"> &
  SelectHTMLAttributes<HTMLSelectElement> & {
      options: Options<T>;
      label?: string; // Label for the input
      validationPattern?: RegExp; // Allow custom regex patterns for validation
      validationMessage?: string; // Custom message to display on validation failure
      required?: boolean; // Is the field required?
      showError?: boolean; // Whether to show an error message
      highlightOnValue?: boolean; // Whether to highlight the input value if present enabled by default.
    },
  "onKeyDown" | "onKeyDownCapture" | "onInput"
>;

const SelectInput = React.forwardRef<HTMLInputElement, TSelectInput>(
  (
    {
      options,
      label,
      showError,
      highlightOnValue = false,
      validationMessage,
      validationPattern,
      ...control
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <Form.Label htmlFor={control.id}>
            {label} {control.required && <span className="text-danger">*</span>}
          </Form.Label>
        )}
        <Form.Select
          {...control}
          ref={ref}
          isInvalid={showError}
          className={
            highlightOnValue
              ? control.className + " filled-field theme-input-control"
              : control.className + " theme-input-control"
          }
          aria-invalid={showError ? "true" : "false"}
        >
          {control.placeholder ? <option value={""}>{control.placeholder}</option> : <option value={""}>Select Value</option>}
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={!!option.disabled}
            >
              {option.label}
            </option>
          ))}
        </Form.Select>
        {showError && (
          <Form.Text className="text-danger">
            {validationMessage || "Invalid input"}
          </Form.Text>
        )}
      </>
    );
  }
);

export default SelectInput;
