import SecondaryButton from "./SecondaryButton";
import React from "react";

function PageBackButton({
  classes,
  onBack,
}: {
  classes?: string;
  onBack: () => void;
}) {
  return (
    <SecondaryButton
      view="regular"
      onClickHandler={onBack}
      classes={
        "d-flex align-items-center gap-2" + (classes ? " " + classes : "")
      }
    >
      <img src="/images/go-back.svg" />
      Back
    </SecondaryButton>
  );
}

export default PageBackButton;
