import axios from "utils/axios";
import { setUserAuthToken } from "utils/headers";

// API : v1/academicclass/getAcademicClasses
export async function getAcademicClasses(body) {

    try{
        return await  axios.post(`academicclass/getAcademicClasses`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/academicsection/getAcademicSections
export async function getAcademicSections(body) {

    try{
        return await  axios.post(`academicsection/getAcademicSections`, body);

    }catch(error){
        return error
    }
    
} 

export async function getBranchsubjects(body) {

    try{
        return await  axios.post(`branchsubject/getBranchSubjects`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/dairy/create
export async function createDairy(body) {

    try{
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await  axios.post(`dairy/create`, body);

    }catch(error){
        return error
    }
    
} 



// API : v1/dairy/general/create
export async function createGeneralDairy(body) {

    try{

        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await  axios.post(`dairy/general/create`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/dairy/getGeneralDairys
export async function getGeneralDairy(body) {

    try{
        return await  axios.post(`dairy/getGeneralDairys`, body);

    }catch(error){
        return error
    }
    
}

// API : v1/dairy/getDairys
export async function getDairies(body) {

    try{
        return await  axios.post(`dairy/getDairys`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/subject/getSubjects
export async function getSubjects(body) {

    try{
        return await  axios.post(`subject/getSubjects`, body);

    }catch(error){
        return error
    }
    
} 


// API : v1/dairy/update
export async function updateDairy(body) {

    try{

        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await  axios.post(`dairy/update`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/dairy/general/update
export async function updateGeneralDairy(body) {

    try{
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await  axios.put(`dairy/general/update`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/dairy/delete
export async function deleteDairy( id) {

    try{
        return await  axios.delete(`dairy/delete/${id}`);

    }catch(error){
        return error
    }
    
}

// API : v1/ptm/create
export async function schedulePTM(body) {

    try{
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await  axios.post(`ptm/admin/web/create`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/ptm/getptmlist
export async function getSchedulePTMs(body) {

    try{
        return await  axios.post(`ptm/admin/web/getptmlist`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/ptm/getptmlist
export async function getSlotWiseSchedulePTMReport(body) {

    try{
        return await  axios.post(`ptm/admin/web/getSlotWiseReport`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/ptm/update
export async function updateSchedulePTM(body) {

    try{
        return await  axios.post(`ptm/admin/web/update`, body);

    }catch(error){
        return error
    }
    
} 
 
// API : v1/ptm/getstudentandduration
export async function getStudentandDuration(body) {

    try{
        return await  axios.post(`ptm/admin/web/getstudentandduration`, body);

    }catch(error){
        return error
    }
    
} 

// API : v1/ptm/delete
export async function deleteSchedulePTM( id) {

    try{
        return await  axios.post(`ptm/admin/web/delete/${id}`);

    }catch(error){
        return error
    }
    
}

// API : v1/activityType/create
export async function createActivityType( body) {
    try{
        return await  axios.post(`activityType/create`,body);

    }catch(error){
        return error
    }
}

// API : v1/activityType/create
export async function getActivityType(body) {
    try{
        return await  axios.post(`activityType/get`,body);

    }catch(error){
        return error
    }
}

// API : v1/activityType/update
export async function updateActivityType(body:any,id:any) {
    try{
        return await  axios.put(`activityType/update/${id}`,body);

    }catch(error){
        return error
    }
}

// API : v1/activity/create
export async function createActivity(body:any) {
    try{
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await  axios.post(`activity/create`,body);

    }catch(error){
        return error
    }
}

// API : v1/activity/get
export async function getActivity(body:any) {
    try{
        return await  axios.post(`activity/get`,body);

    }catch(error){
        return error
    }
}

// API : v1/activity/update
export async function updateActivity(body:any, id:any) {
    try{
        return await  axios.put(`activity/update/${id}`,body);

    }catch(error){
        return error
    }
}

// API : v1/activity/delete
export async function deleteActivity(id:any) {
    try{
        return await  axios.delete(`activity/delete/${id}`);

    }catch(error){
        return error
    }
}

// API : v1/activity/action
export async function actionActivity(body:any, id:any) {
    try{
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await  axios.put(`activity/action/${id}`, body);

    }catch(error){
        return error
    }
}

// DAIRY SUBMISSIOM SUBJECT

export async function getDairySubmissionReport(body:any) {
    try{
        return await  axios.post(`dairysubmission/getDairySubmissions`, body);

    }catch(error){
        return error
    }
}

export async function getBranchAcademicYearMonths(body:any) {
    try{
        return await  axios.post(`branchacademicbatch/getBranchAcademicYearMonths`, body);

    }catch(error){
        return error
    }
}

// DIARY CREATION SETTINGS

// GET
export async function getDiaryCreationSettings(body) {
    try{
        return await  axios.post(`diaryCreationSettings/getSettings`,body);

    }catch(error){
        return error
    }
}

// update
export async function updateDiaryCreationSetting(body:any,id:any) {
    try{
        return await  axios.put(`diaryCreationSettings/update/${id}`,body);

    }catch(error){
        return error
    }
}

// create
export async function createDiaryCreationSetting(body:any) {
    try{
        // if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await  axios.post(`diaryCreationSettings/create`,body);

    }catch(error){
        return error
    }
}

// Staff Slot Check 

export async function getStaffSlotCheck(body:any) {
    try{
        return await  axios.post(`timetable/staffSlotCheck`,body);

    }catch(error){
        return error
    }
}
    




