import SelectInput from "components/Forms/SelectInput";
import useBranchLevel from "components/hooks/useBranchLevel";
import useLocalStorage from "components/hooks/useLocalStorage";
import React, { useEffect, useMemo, useState } from "react"
import { BranchValues, FilterType, Options } from "utils/helper/types";

// Defines the props that the `Branch` component can accept.
export type BranchProps = {
  setter: (values: BranchValues, from ?: FilterType) => void; // A function to set branch-related values.

  // Conditional type: Either `getter` and `getWhen` are defined, or both are undefined.
} & (
  | {
      getter?: undefined; // `getter` is undefined if not used.
      getWhen?: undefined; // `getWhen` is undefined if not used.
    }
  | {
      getter: (values: BranchValues) => void; // A function to retrieve branch-related values when `getWhen` condition is met.
      getWhen: keyof BranchValues; // Specifies which key in `BranchValues` triggers the `getter` function.
    }
  ) &
  (
    // Specifies different configurations for additional options like year, class, section, etc.
    | {
        addYear: true; // Indicates that an academic year is included.
        addClass?: boolean; // Optional: Indicates whether a class is included.
        addSection?: boolean; // Optional: Indicates whether a section is included.
        addCombinedClassSection?: undefined; // Not included if `addCombinedClassSection` is used.
      }
    | {
        addYear: true; // Academic year is always included.
        addClass?: undefined; // Class is not included if this configuration is used.
        addSection?: undefined; // Section is not included if this configuration is used.
        addCombinedClassSection?: boolean; // Optional: Combines class and section in a single selection.
      }
    | {
      addYear?: boolean; // Optional: Indicates whether an academic year is included.
      addClass?: undefined; // Class is not included.
      addSection?: undefined; // Section is not included.
      addCombinedClassSection?: undefined; // Combined class-section is not included.
    }
  );
  
function Branch({setter, getter, getWhen, addYear, addClass, addSection, addCombinedClassSection} : BranchProps) {
    const [use_role_level] = useLocalStorage("use_role_level", "")

    const [orgId] = useLocalStorage("orgId", "")
    const [branchId] = useLocalStorage("branchId", "")
    const [branchAcademicBatchId] = useLocalStorage("branchAcademicBatchId", "")

    useEffect(() => {
      if(orgId){
        branchApi.action({orgId});
      }
    }, [orgId])

    useEffect(() => {
      if(use_role_level === "branch" && branchId){
        if(addYear)
          branchAcademicBatchApi.action({branchId});
        setSelectedValues((prev) => ({...prev, branchId}))
      }
    }, [use_role_level,branchId])

    useEffect(() => {
      if(use_role_level === "branch" && branchAcademicBatchId){
        setSelectedValues((prev) => ({...prev, branchAcademicBatchId}))
        if(addClass){
          academicClassesApi.action({branchAcademicBatchId});
        } else if (addCombinedClassSection){
          classSectionsApi.action({branchAcademicBatchId})
        }
      }
    }, [use_role_level,branchAcademicBatchId])
  
    const {branchApi, branchAcademicBatchApi, classSectionsApi, academicClassesApi, academicSectionsApi} = useBranchLevel({branchAcademicBatches : true, 
      classes : true,
      sections : true,
      combinedClassSections : true
    })

    const [selectedValues, setSelectedValues] = useState<BranchValues>({
        branchId : "",
        branchAcademicBatchId : "",
        academicClassId : "",
        academicSectionId : ""
    })

    const branches : Options<string> = useMemo(() => {
        return (branchApi.data ?? []).map((branch) => {
          return {
            label: branch.branch_name,
            value: branch._id,
          };
        });
      }, [branchApi?.data]);

    const branchAcademicBatches : Options<string> = useMemo(() => {
        return (branchAcademicBatchApi.data ?? []).map((year) => {
          return {
            label: year.name,
            value: year._id,
          };
        });
    }, [branchAcademicBatchApi?.data]);

    const academicClasses: Options = useMemo(() => {
      if (!academicClassesApi?.data) return [];
      return academicClassesApi?.data?.map((item: any) => {
        return {
          value: item._id,
          label: item.branchClass?.[0]?.name,
        };
      });
    }, [academicClassesApi?.data]);

    const academicSections: Options = useMemo(() => {
      if (!academicSectionsApi?.data) return [];
      return academicSectionsApi?.data?.map((item: any) => {
        return {
          value: item._id,
          label: item.section?.[0]?.sectionName,
        };
      });
    }, [academicSectionsApi?.data]);
  
    const classSections: Options = useMemo(() => {
      return (classSectionsApi?.data ?? []).map((cls) => {
        return {
          label: cls?.className + "-" + cls?.sectionName,
          value: `${cls?.academicClassId}:-:${cls.academicSectionId}`,
        };
      });
    }, [classSectionsApi?.data]);

    return (<>
        <SelectInput
            placeholder="Branch"
            disabled={use_role_level === "branch"}
            value={selectedValues.branchId}
            className="theme-input-control width-filter"
            onChange={async (e) => {
                const newValues = {...selectedValues, branchId : e.target.value, branchAcademicBatchId : '', academicClassId : '', academicSectionId : ''} as BranchValues

                if(e.target.value && addYear){
                  const data = await branchAcademicBatchApi.action({branchId : e.target.value});
                  const currentYear = data.filter(({isCurrentAcademicYear}) => !!isCurrentAcademicYear)?.[0]?._id || ""
                  newValues.branchAcademicBatchId = currentYear;
                  
                  if(currentYear){
                    if(addClass){
                      academicClassesApi.action({branchAcademicBatchId : newValues.branchAcademicBatchId});
                    } else if (addCombinedClassSection){
                      classSectionsApi.action({branchAcademicBatchId : newValues.branchAcademicBatchId})
                    }
                  }
                } else {
                  branchAcademicBatchApi.clearActions.clearData();
                }

                if(addClass) {
                  academicClassesApi.clearActions.clearData();
                }
                if(addSection) {
                  academicSectionsApi.clearActions.clearData();
                }
                if(addCombinedClassSection) {
                  classSectionsApi.clearActions.clearData();
                }
                                
                if(getWhen === "branchId") {
                    getter(newValues)
                }
              
                setter(newValues, "branch")
                setSelectedValues(newValues)
            }}
            options={branches}
        />
        {addYear && <SelectInput
            placeholder="Academic Year"
            disabled={use_role_level === "branch"}
            value={selectedValues.branchAcademicBatchId}
            className="theme-input-control width-filter"
            onChange={(e) => {
                const newValues = {...selectedValues, branchAcademicBatchId : e.target.value, academicClassId : '', academicSectionId : ''} as BranchValues
                
                if(e.target.value){
                  if(addClass){
                    academicClassesApi.action({branchAcademicBatchId : e.target.value});
                  }
                  else if(addCombinedClassSection){
                    classSectionsApi.action({branchAcademicBatchId : e.target.value});
                  }
                } else if(addClass) {
                  academicClassesApi.clearActions.clearData();
                }
                else if(addCombinedClassSection) {
                  classSectionsApi.clearActions.clearData();
                }

                if(addSection) {
                  academicSectionsApi.clearActions.clearData();
                }
                
                if(getWhen === "branchAcademicBatchId") {
                    getter(newValues)
                }
                
                setter(newValues, "branch")
                setSelectedValues(newValues)
            }}
            options={branchAcademicBatches}
        />}
        {addClass && <SelectInput
            placeholder="Class"
            value={selectedValues.academicClassId}
            className="theme-input-control width-filter"
            onChange={(e) => {
                const newValues = {...selectedValues, academicClassId : e.target.value, academicSectionId : ''} as BranchValues
                
                if(e.target.value) {
                  academicSectionsApi.action({academicClassId : e.target.value});
                }
                else if(addSection) {
                  academicSectionsApi.clearActions.clearData();
                }

                if(getWhen === "academicClassId") {
                    getter(newValues)
                }
                
                setter(newValues, "branch")
                setSelectedValues(newValues)
            }}
            options={academicClasses}
        />}
        {addSection && <SelectInput
            placeholder="Section"
            value={selectedValues.academicSectionId}
            className="theme-input-control width-filter"
            onChange={(e) => {
                const newValues = {...selectedValues, academicSectionId : e.target.value} as BranchValues
                
                if(getWhen === "academicSectionId"){
                    getter(newValues)
                }
                
                setter(newValues, "branch")
                setSelectedValues(newValues)
            }}
            options={academicSections}
        />}
        {addCombinedClassSection && <SelectInput
            placeholder="Class & Section"
            value={selectedValues.academicClassId + ":-:" + selectedValues.academicSectionId}
            className="theme-input-control width-filter"
            onChange={(e) => {
                const [academicClassId, academicSectionId] = e.target.value.split(":-:")
                const newValues = {...selectedValues, academicClassId, academicSectionId } as BranchValues
                
                if(getWhen === "academicClassId" || getWhen === "academicSectionId"){
                    getter(newValues)
                }
                
                setter(newValues, "branch")
                setSelectedValues(newValues)
            }}
            options={classSections}
        />}
    </>);
}

export default Branch;