import { Dispatch } from "redux";
import axios from "../../utils/axios";
import { setUserAuthToken } from "../../utils/headers";
import types from "./types";
import { RoleActions,IRoleState } from "redux/types/role";
import { setAlert } from "redux/actions/alert";
import { AlertActions } from "redux/types/alert";



let orgId = localStorage.getItem("orgId")

// REGISTER USER
export const registerRole =
  (body: any, navigate: any) =>
    async (dispatch: Dispatch<RoleActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`role/create`, body, config);
        if(data?.errors?.length){
          let msg = "";
          data?.errors?.map((item) => {
            msg += item
          })
          dispatch<any>(setAlert({
            msg: msg,
            status: 200,
            alertType: "danger",
          }));
          dispatch<any>(getRolesById({orgId}));
          return;
        } else {
          dispatch({
            type: types.ROLE_REGISTER_SUCCESS,
            payload: data,
          });
          
          dispatch<any>(setAlert({
            msg: "Role registered successfully!",
            status: 200,
            alertType: "success",
          }));
          dispatch<any>(getRolesById({orgId}));
        }
        // navigate("/rolelist");
      } catch (error) {
        dispatch({ type: types.ROLE_REGISTER_FAIL });
        dispatch<any>(setAlert({
          msg: error?.response?.data?.message || "Failed to register role",
          status: error?.response?.status || 500,
          alertType: "danger",
        }));
      }
    };
// GET USERS
export const getRoles = () => async (dispatch: Dispatch<RoleActions>) => {
  const config: any = {
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.get(`role/roles`, config);
    dispatch({ type: types.GET_ROLES, payload: {role : data["role"] }});
  } catch (error) { }
};

// UPDATE USER DATA
export const updateRole =
  (body: any, id: any, navigate: any) =>
    async (dispatch: Dispatch<RoleActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.patch(`role/updateRole/${id}`, body, config);
        dispatch({
          type: types.UPDATE_ROLE,
          payload: data,
        });
        dispatch<any>(getRolesById({orgId}));
        // navigate("/rolelist");
      } catch (error) {
      }
    };

// DELETE USER
export const deleteRole =
  (id: number) => async (dispatch: Dispatch<RoleActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      await axios.delete(`user/users/${id}`, config);
      dispatch({ type: types.DELETE_ROLE, payload: id });
    } catch (error) { }
  };

// GET ROLES BY ORGID
export const getRolesById = (body: any, setIsLoading?: any) => async (dispatch: Dispatch<RoleActions>) => {
  const config: any = {
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.post(`role/getRolesByOrgId`,body, config);
    dispatch({ type: types.GET_ROLES, payload: {role : data["role"], totalCount : data.totalCount }});
    if (setIsLoading) setIsLoading(false);
  } catch (error) {
    if (setIsLoading) setIsLoading(false);
    dispatch<any>(
      setAlert({
          msg: "Something went wrong when fetching the roles!",
          status: error?.response?.status,
          alertType: "danger",
      })
  );
   }
};
export const getallRoles =
  (body: any) =>
    async (dispatch: Dispatch<RoleActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`role/getRoles`, body, config);
        dispatch({ type: types.GET_ROLES, payload: {role : data } });

      } catch (error) {
      }
    };
// GET ROLES BY BRANCHID
export const getRolesBybranchId = (id: any) => async (dispatch: Dispatch<RoleActions>) => {
  const config: any = {
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.get(`role/getRolesByBranchId/${id}`, config);
    dispatch({ type: types.GET_ROLES, payload: { role : data["role"] }});
  } catch (error) { }
};