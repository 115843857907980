import useApi from "components/hooks/useApi";
import React, { useEffect } from "react"
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setAlert } from "redux/actions/alert";
import { switchUserAccount } from "redux/actions/user";
import { RootState } from "redux/reducers";
import { getUserAccountsByStaffId } from "services/hr";

function AccountSelector() {
    const user = useSelector((state : RootState) => state.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {action : getUserAccountBStaffIdAction, data : userAccountsResponse} = useApi(getUserAccountsByStaffId)

    useEffect(() => {
        if (user && user._id){
            getUserAccountBStaffIdAction(user._id)
        }
    }, [user])

    const handleChange = (e : React.ChangeEvent<HTMLSelectElement>) => {
      if(!e.target.value){
        dispatch(setAlert({
          msg: "Please Select Valid Account",
          status: 200,
          alertType: "warning",
        }))
        return;
      }
        const branchId = e.target.value;
        const userId = user._id;
        dispatch(switchUserAccount({branchId, userId}, navigate));
    }
    return (<>
        {userAccountsResponse?.length > 1 && <Form.Select
            onChange={handleChange}
            aria-label="Default select example"
            value={user.branchId}
            className="theme-input-control width-filter academic-year-header-light"
            style={{ border: "1px solid #DDDDDD !important" }}
          >
            <option value={""}>Select Account</option>
            {userAccountsResponse && userAccountsResponse.length ? (
              userAccountsResponse.map((e: any, key: any) => {
                return (
                  <option key={key} value={e.branchId._id}>
                    {" "}
                    {e.branchId.branch_name}
                  </option>
                );
              })
            ) : (
              <option value={""}>No Accounts</option>
            )}
        </Form.Select>}
    </> );
}

export default AccountSelector;